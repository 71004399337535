// IMPORTS
@import (reference) "../settings.less";

// MAIN BANNER
.main-banner-wrapper {
    width: 1920px;
    position: relative;

    .inner {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
    }

    &:before { // black bg below header on scroll
        .full-bg();
        height: 150%;
        background-color: @header-color;
        top: -50%;
    }
}

.main-banner {
    width: 100%;

    .banner-img-wrapper {
        position: absolute;
        inset: 0;

        img {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            top: 0;
            height: 100%;
            width: auto;
            transition: opacity 0.3s ease-in-out;

            &.hide {
                opacity: 0;
            }
        }
    }

    .swiper-slide {
        position: relative;
        height: 640px;

        &.swiper-slide-active {
            z-index: 1;
        }
    }
}

.text-wrapper-container {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 100%;
    aspect-ratio: ~"1440/640";

    &.hide-text {

        .text-wrapper {
            visibility: hidden;
            opacity: 0;
            pointer-events: none;
        }
    }

    .text-wrapper {
        position: absolute;
        top: 64px;
        left: 0;
        right: 0;
        text-align: center;
        margin: 0 auto;
        padding: 0 10px;
        width: 720px;
        max-width: 80%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 12px;
        color: white;
        line-height: 1.2;
        transition: visibility 0.3s ease-in-out, opacity 0.3s ease-in-out;

        > b {
            font-size: 40px;
            line-height: 1.2;
            font-family: @sec-font-family;
            font-weight: normal;
        }

        > span {
            font-size: 18px;
            font-weight: 300;
            max-width: 80%;
            line-height: 1.4;
        }

        .slide-button {
            font-weight: 600;
            font-size: 12px;
            text-transform: uppercase;
            letter-spacing: 0.1em;
            border: 1px solid white;
            padding: 12px 40px;
            color: white;
            margin-top: 20px;

            &:before {
                content: 'Sprawdź';
            }

            &.plays-video {
                width: 80px;
                height: 80px;
                padding: 0;
                border-radius: 50%;
                border-width: 2px;
                position: relative;

                &:before { // triangle
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: calc(50% + 4px);
                    transform: translate(-50%, -50%);
                    width: 0;
                    height: 0;
                    border-top: 15px solid transparent;
                    border-bottom: 15px solid transparent;
                    border-left: 30px solid white;
                }
            }

            @media(min-width: 1280px) {
                &:hover {
                    border-color: @main-color;
                }
            }
        }
    }

    iframe {
        .img-contain();
        pointer-events: none;
    }

    .text-wrapper:has(.slide-button:only-child) {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        position: relative;

        .slide-button {
            position: absolute;
            top: calc(50% - 64px);
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
}

// MAIN BANNER PAGINATION
.swiper-pagination.banner-pagination {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: auto;
    display: flex;

    .swiper-pagination-bullet {
        border-radius: 0;
        opacity: 1;
        margin: 0;
        width: 40px;
        height: 48px;
        position: relative;
        margin-right: 6px;
        background-color: transparent;

        &:after {
            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            width: 100%;
            background-color: transparent;
            height: 8px;
            transform: translateY(-50%);
            border: 1px solid white;
            transition: border-color 0.2s ease-in;
        }

        &.swiper-pagination-bullet-active {

            &:after {
                border-color: @main-color;
            }
        }
    }
}


// PROMO BLACK BARS
.promo-bar {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    color: white;
    padding-block: 36px 44px;
    position: relative;
    background: @header-color;
    gap: 18px 64px;

    &:before {
        .full-bg();
    }

    .headline {
        color: white;
        margin-bottom: 0 !important;
    }

    a {
        padding: 12px 40px;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 0.1em;
        border: 1px solid white;
        color: white;

        @media (min-width: 1280px) {
            &:hover {
                border-color: @main-color;
            }
        }
    }

    &:has(a) {
        padding-block: 38px;
    }

    &.alternating-banners-headline {
        margin-top: calc(-1 * var(--rowGap)) !important;
        margin-bottom: 0 !important;
    }
}

// GREETING
.mainpage-greeting {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    position: relative;
    padding-block: 96px;

    &:before {
        .full-bg();
        background-color: @light-bg;
    }

    .headline {
        font-size: 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 32px;
        margin-bottom: 32px;

        &:after {
            content: '';
            width: 1px;
            height: 32px;
            background: @main-color;
        }
    }

    .greeting-body {
        max-width: 1240px;
    }
}