// COLORS
@font-color: #000000;
@header-color: #000000;
@border-color: #DEDDD9;
@main-color: #CDB33C;
@sec-color: #000000;
@success-color: #287D00;
@error-color: #FF0303;
@light-bg: #F1F0EC; 
@footer-gray: #D8D8D8;

// FONTS
@main-font-family: 'Nunito Sans', 'Adjusted Arial Fallback';
@sec-font-family: 'Playfair Display', 'Adjusted Times New Roman Fallback';

// IMAGES
@small-width: 330px;
@small-height: 330px;

@full-width: 600px;
@full-height: 600px;

@custom-width: 100px;
@custom-height: 100px;

:root {
    --prodCols: 3;
    --prodSwiperCols: 3;
    --prodDetailCols: 4;
    --prodGap: 57px;
    --mainpageSliderGap: 45px;
    --fontSize: 16px;
    --lineHeight: 1.5;
    --rowGap: 72px;
    --borderRadius: 0;
    --iconSize: 20px;
    --swiper-navigation-sides-offset: -20px;
    --successColorForJS: @success-color;

    // CART
    --cartGap: 35px;
}

// INPUTS
input[type=text],
input[type=password],
input[type=email],
select {
    padding: 0 18px;
    width: 100%;
    border: 1px solid @border-color;
    height: 50px;
    line-height: normal;
    font-size: inherit;
    font-family: inherit;
    background-color: white;
    position: relative;
    border-radius: var(--borderRadius);
}

textarea {
    padding: 10px 23px;
    font-size: inherit;
    font-family: inherit;
    width: 100%;
    resize: vertical;
    border: 1px solid @border-color;
    border-radius: var(--borderRadius);
}

.form-hint {
    padding: 4px 0;
    color: @sec-color;
    font-size: 12px;
}

input[type="radio"]:focus-visible + label,
input[type="checkbox"]:focus-visible + label,
*:focus-visible {
    outline: 2px dotted @main-color !important;
    box-shadow: 0 0 1px white;
    outline-offset: 1px;
}

iframe:focus-visible {
    outline: none !important;
}

input[type="checkbox"],
input[type="radio"] {
    height: 100%;
}

input, 
select {
    font-family: inherit;
}

.swiper-slide:focus-visible {
    outline-offset: -3px;

    img {
        position: static;
    }
}

select option {
    font-family: Arial, sans-serif;
}

main {
    .grid();
    padding: 0 0 var(--rowGap);
    grid-gap: var(--rowGap) 56px;

    &:not(.aside) {
        grid-template-columns: minmax(1px, 1fr);

        #tree {
            display: none;
        }
    }

    &.aside {
        grid-template-columns: 280px minmax(1px, 1fr);
        grid-template-rows: auto 1fr;
        margin-top: 40px;

        #newsletter,
        .producers-banner {
            grid-column: span 2;
        }

        #breadcrumb {
            grid-column: 2 / 3;
            grid-row: 1 / 2;
        }

        &:has(#breadcrumb) {
            .sidebar {
                grid-row: span 2;
            }
        }

        #content {
            grid-column: 2 / 3;
        }
}
}

@media (max-width: 1460px) and (min-width: 1280px) {
    :root {
        --prodGap: 16px;
        --rowGap: 64px;
    }
}

@media (max-width: 1279px) {
    :root {
        --prodCols: 3;
        --prodSwiperCols: 3;
        --prodDetailCols: 3;
        --prodGap: 30px 20px;
        --rowGap: 26px;
        --mainpageSliderGap: var(--rowGap);
    }

    .xsell-wrapper .product:nth-child(n+4) {
        display: none;
    }
}

@media (max-width: 1099px) {
    :root {
        --prodCols: 3;
        --prodSwiperCols: 3;
        --prodDetailCols: 3;
    }
}

@media (max-width: 900px) {
    :root {
        --prodSwiperCols: 2;
    }
}

@media (max-width: 600px) {
    :root {
        --prodCols: 2;
        --prodDetailCols: 2;
    }

    .xsell-wrapper .product:nth-child(n+4) {
        display: grid;
    }
}

@media (max-width: 530px) {
    :root {
        --prodSwiperCols: 1;
    }
}

@media (max-width: 374px) {
    :root {
        --prodCols: 1;
        --prodSwiperCols: 1;
        --prodDetailCols: 1;
    }
}

.main-page-products-wrapper {

    .product {
        width: ~"calc((100% - ((var(--prodSwiperCols) - 1) * var(--prodGap))) / var(--prodSwiperCols))";
        margin-right: var(--prodGap);
    }
}

// METHODS
.img-crop {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
}

.img-contain {
    width: 100%;
    height: 100%;
    object-fit: scale-down;
    display: block;
}

.flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    img {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
    }
}

.shadow() {
    box-shadow: 0 3px 8px rgba(0,0,0,.2);
}

.center-lr() {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.line-height() {
    line-height: ~"calc(var(--fontSize) * var(--lineHeight))";
}

.grid() {
    display: grid;
    grid-auto-rows: max-content;
}

.small-icon {
    font-family: icomoon;
    line-height: 1;
    display: block;
    font-weight: normal;
    font-style: normal;
    text-transform: none;
    text-decoration: none;
    color: inherit;
    font-size: var(--iconSize);
}

.hr-after {
    content: "";
    background-color: @border-color;
    position: absolute;
    width: 101vw;
    height: 1px;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
}

.full-bg {
    content: "";
    background-color: inherit;
    position: absolute;
    width: 101vw;
    height: 100%;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
}

// LAZYLOAD ANIMATION
.lazyload {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;

    &.loaded {
        opacity: 1;
    }
}

// SETTINGS
html {
    box-sizing: border-box;
}

source {
    display: none;
}

img.lazyload,
.swiper-lazy {
    opacity: 0;
    transition: opacity .2s ease-in-out;

    &.loaded,
    &.swiper-lazy-loaded {
        opacity: 1;
    }
}

*, *:before, *:after {
    box-sizing: inherit;
    margin: 0;    
    padding: 0;
}

ul, ol {
    list-style: none;
}

article, 
aside, 
details, 
figcaption, 
figure, 
footer, 
header, 
hgroup, 
menu, 
nav, 
section {
  display: block;
}
 
a:active,
a:visited,
a:focus,
a {
    text-decoration: none;
    outline: none;
    color: @main-color;
}

a img {
    border: none;
}

textarea, input, button { 
    -webkit-appearance: none;
    outline: none; 
    border-radius: var(--borderRadius);
    border: 1px solid @border-color;
    color: @font-color;
}

button {
    border: 0; 
    background: none; 
    text-align: center;
    cursor: pointer;
    font-family: inherit;
    font-size: inherit;
}

button::-moz-focus-inner, 
input[type=button]::-moz-focus-inner, 
input[type=submit]::-moz-focus-inner, 
input[type=reset]::-moz-focus-inner {
    padding: 0 !important;
    border: 0 none !important;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
}

input[type=number] {
    -moz-appearance: textfield;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}

input {

    &::-webkit-input-placeholder {
        opacity: 1;
    }

    &::-moz-placeholder {
        opacity: 1;
    }

    &:-ms-input-placeholder {
        opacity: 1;
    }

    &:-moz-placeholder {
        opacity: 1;
    }
}

input::-ms-clear {
    display: none;
}

h1, h2, h3, h4, h5, h6, h7, h8 {
    display: block;
    clear: both;
    color: @header-color;
    font-weight: 700;
}

hr {
    margin: 30px 0;
    border: none;
    height: 1px;
    background-color: @border-color;
}

.swiper-slide {
    box-sizing: border-box;
}

body {
    font-family: @main-font-family;
    font-size: var(--fontSize);
    color: @font-color;
    line-height: var(--lineHeight);
}

.inner {
    width: 1440px;
    margin: 0 auto;
}

// BUTTONS
.confirm-button,
.return-button {
    font-weight: 600;
    font-size: 14px;
    text-transform: uppercase;
    padding: 8px 24px;
    cursor: pointer;
    height: 50px;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    width: fit-content;
    letter-spacing: 0.1em;
}

.confirm-button,
a.confirm-button {
    background-color: @main-color;
    color: @header-color;

    &:hover {
        background-color: darken(@main-color, 10%);
    }
}

[data-submitting] .confirm-button,
.confirm-button.button-disabled,
.confirm-button[disabled] {

    &:not(.facebook-login-button):not(.google-login-button):not(.apple-login-button) {
        background-color: @border-color;
        color: @font-color !important;
        cursor: not-allowed;
    }
}

.return-button,
a.return-button {
    background-color: @sec-color;
    color: white;
    border: 1px solid white;

    &:hover {
        border-color: @main-color;
    }
}

.user-custom-content {

    img:not(.img-contain):not(img-crop) {
        height: auto !important;
        max-width: 100% !important;
    }

    p {
        margin-bottom: 14px;
    }

    a {
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }

    ul {
        margin: 14px 0;
        list-style: disc;
        padding-left: 15px;

        > li::marker {
            color: @main-color;
            font-size: 1.1em;
        }
    }

    ol {
        list-style: decimal !important;
        padding-left: 20px;
        margin: 14px 0;
    }

    ul,
    ol {

        a {
            color: @sec-color;
            font-weight: 600;
        }
    }

    hr {
        margin: 0 0 23px !important;
    }

    h1:not(.headline) {
        font-size: 1.8em;
        font-family: @sec-font-family;
        font-weight: 400;
    }

    h2:not(.headline) {
        font-size: 1.5em;
        font-family: @sec-font-family;
        font-weight: 400;
    }

    h3:not(.headline) {
        font-size: 1.2em;
        font-family: @sec-font-family;
        font-weight: 400;
    }
}

// HEADLINE
.headline {
    clear: both;
    font-size: 32px;
    line-height: 1.2;
    display: block;
    font-weight: normal;
    color: @header-color;
    margin-bottom: 20px;
    font-family: @sec-font-family;
}

// SWIPER BUTTONS
.swiper-button-prev,
.swiper-button-next {
    background-color: white;
    width: 40px;
    height: 40px;
    margin-top: -20px;
    border: 1px solid @header-color;
    color: @header-color;

    &.swiper-button-disabled {
        pointer-events: auto;
    }

    &:after {
        content: "\e91a";
        font-family: icomoon;
        font-size: 24px;
        color: inherit;
    }

    &:not(.swiper-button-disabled):hover {
        color: @main-color;
    }
}

.swiper-button-prev {

    &:after {
        transform: rotate(90deg);
    }
}

.swiper-button-next {

    &:after {
        transform: rotate(-90deg);
    }
}

@keyframes spin {
    0% {
        transform: translateZ(0) rotate(0deg);
    }

    100% {
        transform: translateZ(0) rotate(1turn);
    }
}

// LOADER
#loading {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(255,255,255,.5);
    z-index: 99999;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity .2s linear;
    pointer-events: none;

    &.show {
        opacity: 1;
        pointer-events: auto;
    }

    div {
        border-radius: 50%;
        border: 4px solid @border-color;
        border-top-color: @header-color;
        animation: spin .5s infinite linear;
        transform: translateZ(0) rotate(0deg);
        transform-origin: center;
        -webkit-transform-style: preserve-3d;
        width: 100px;
        height: 100px;
    }
}

// TABS
.gs-tabs {

    &:empty {
        display: none;
    }

    &.tab-done {

        .products-list-tabs {

            > *:first-child {
                display: none;
            }
        }
    }

    nav {
        display: flex;
        touch-action: manipulation;
    }
    
    &:not(.tab-done) .products-list-tabs {

        &:nth-of-type(n+2) {
            display: none;
        }
    }
}

// MOTION REDUCTION
@media (prefers-reduced-motion) {
    *, *:before, *:after {
        transition-duration: unset !important;
    }
}

// ICONS
.icon-cart-custom:before {
  content: "\e90f";
}
.icon-user-custom:before {
  content: "\e910";
}
.icon-search-custom:before {
  content: "\e911";
}
.icon-instagram-custom:before {
  content: "\e912";
}
.icon-facebook-custom:before {
  content: "\e913";
}
.icon-phone-custom:before {
  content: "\e914";
}
.icon-mail-custom:before {
  content: "\e915";
}
.icon-circle-close-custom:before {
  content: "\e916";
}
.icon-user-check-custom:before {
  content: "\e917";
}
.icon-chat-custom:before {
  content: "\e918";
}
.icon-truck-custom:before {
  content: "\e919";
}
.icon-arrow-custom:before {
  content: "\e91a";
}
.icon-main-cart:before {
  content: "\e90e";
}
.icon-x:before {
  content: "\e90d";
}
.icon-cart-icon4:before {
  content: "\e90c";
}
.icon-cart-icon1:before {
  content: "\e91d";
}
.icon-cart-icon2:before {
  content: "\e91e";
}
.icon-cart-icon3:before {
  content: "\e91f";
}
.icon-cart-icon5:before {
  content: "\e920";
}
.icon-cart-icon6:before {
  content: "\e921";
}
.icon-cart-icon7:before {
  content: "\e922";
}
.icon-cart-icon8:before {
  content: "\e923";
}
.icon-cart-icon9:before {
  content: "\e924";
}
.icon-keyboard_arrow_left:before {
  content: "\e90b";
}
.icon-trash-2:before {
  content: "\e90a";
}
.icon-circle:before {
  content: "\e905";
}
.icon-alert-circle:before {
  content: "\e901";
}
.icon-help-circle:before {
  content: "\e902";
}
.icon-info:before {
  content: "\e900";
}
.icon-check:before {
  content: "\e801";
}
.icon-check-square:before {
  content: "\e816";
}
.icon-credit-card:before {
  content: "\e815";
}
.icon-lock:before {
  content: "\e812";
}
.icon-smartphone:before {
  content: "\e813";
}
.icon-square:before {
  content: "\f096";
}
.icon-star-empty:before {
  content: "\e810";
}
.icon-star:before {
  content: "\e80f";
}
.icon-review:before {
  content: "\e80c";
}
.icon-chat:before {
  content: "\e814";
}
.icon-clock:before {
  content: "\e802";
}
.icon-box:before {
  content: "\e80b";
}
.icon-close:before {
  content: "\e803";
}
.icon-pin:before {
  content: "\e808";
}
.icon-arrow:before {
  content: "\e800";
}
.icon-search:before {
  content: "\e80d";
}
.icon-user:before {
  content: "\e811";
}
.icon-heart:before {
  content: "\e809";
}
.icon-cart:before {
  content: "\e80e";
}
.icon-instagram:before {
  content: "\e807";
}
.icon-facebook1:before {
  content: "\e806";
}
.icon-mail:before {
  content: "\e805";
}
.icon-phone:before {
  content: "\e80a";
}
.icon-truck:before {
  content: "\e804";
}
.icon-alert-circle1:before {
  content: "\e903";
}
.icon-check-circle:before {
  content: "\e904";
}
.icon-youtube-play:before {
  content: "\e909";
}
.icon-pinterest-p:before {
  content: "\e906";
}
.icon-facebook:before {
  content: "\e907";
}
.icon-facebook-f:before {
  content: "\e907";
}
.icon-twitter:before {
  content: "\e908";
}